// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-templates-list-blog-list-js": () => import("./../../../src/templates/list/blog-list.js" /* webpackChunkName: "component---src-templates-list-blog-list-js" */),
  "component---src-templates-list-category-js": () => import("./../../../src/templates/list/category.js" /* webpackChunkName: "component---src-templates-list-category-js" */),
  "component---src-templates-list-series-js": () => import("./../../../src/templates/list/series.js" /* webpackChunkName: "component---src-templates-list-series-js" */),
  "component---src-templates-single-blog-post-js": () => import("./../../../src/templates/single/blog-post.js" /* webpackChunkName: "component---src-templates-single-blog-post-js" */),
  "component---src-templates-single-category-blog-post-js": () => import("./../../../src/templates/single/category-blog-post.js" /* webpackChunkName: "component---src-templates-single-category-blog-post-js" */),
  "component---src-templates-single-page-js": () => import("./../../../src/templates/single/page.js" /* webpackChunkName: "component---src-templates-single-page-js" */),
  "component---src-templates-single-series-blog-post-js": () => import("./../../../src/templates/single/series-blog-post.js" /* webpackChunkName: "component---src-templates-single-series-blog-post-js" */)
}

